import request from '@/utils/request'

export const getSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/host_open_close_applications/setup'
  })
}

export const getHostOpenCloseApplications = (params) => {
  return request({
    method: 'GET',
    url: '/cms/host_open_close_applications',
    params
  })
}

export const getHostOpenCloseApplicationsId = (id) => {
  return request({
    method: 'GET',
    url: `/cms/host_open_close_applications/${id}`
  })
}
