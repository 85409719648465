// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '80'
  }, {
    prop: 'host_name',
    label: '',
    Object: 'value',
    width: '180'
  }, {
    prop: 'full_address',
    label: '',
    Object: 'value',
    width: '180'
  }, {
    prop: 'application_type',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'effective_date',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'remarks',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'application_status',
    label: '',
    Object: 'value',
    width: '120'
  }]
}
